import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import terapia1 from "./../assets/terapia1.jpg";
import natacion1 from "./../assets/natacion1.jpg";
import natIco1 from "./../assets/icons/natIco1.png";
import ubicacion from "./../assets/icons/ubicacion.png";
import relog from "./../assets/icons/relog.png";
import ninos from "./../assets/icons/ninos.png";
import puzzle from "./../assets/icons/puzzle.png";

const transferFeatures = [
  {
    id: 4,
    name: "Descripción de la terapia",
    description:
      "El objetivo principal es potenciar el desarrollo de los niños, favoreciendo su participación  y desempeño en las distintas actividades del día a día. Se utiliza como herramienta terapéutica el juego, ya que es la ocupación más significativa  en la infancia y el eje de su desarrollo cognitivo, social y emocional.",
    icon: puzzle,
  },
  {
    id: 1,
    name: "Población objetivo",
    description: "Atención: 0 a 18 años",
    icon: ninos,
  },
  {
    id: 2,
    name: "Duración de las sesiones",
    description: "45 minutos",
    icon: relog,
  },
  {
    id: 3,
    name: "Lugares",
    description: "Centro Volantín",
    icon: ubicacion,
  },
];
const communicationFeatures = [
  {
    id: 1,
    name: "Descripción de las clases",
    description:
      "Iniciación en el medio acuático desde los 2 años, familiarización y  seguridad en el agua, inmersión, salto y piqueros, flotación con y sin apoyo, desplazamiento en el medio acuático hasta técnica y estilos de nado (mariposa, espalda, pecho y crol).",
    icon: natIco1,
  },
  {
    id: 2,
    name: "Población objetivo",
    description: "2 años  en adelante",
    icon: ninos,
  },
  {
    id: 2,
    name: "Duración de las clases",
    description: "45 minutos",
    icon: relog,
  },
  {
    id: 2,
    name: "Lugares",
    description: "A Domicilio y Centro Care",
    icon: ubicacion,
  },
];

export default function Trayectoria() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24" id="servicios">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-indigo-400"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            Servicios
          </h2>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3
              className="text-2xl font-extrabold text-indigo-600 tracking-tight sm:text-3xl"
              // data-aos="fade-right"
            >
              Terapia Ocupacional
            </h3>
            <p
              className="mt-3 text-justify text-lg text-gray-500"
              // data-aos="fade-right"
            >
              La Terapia Ocupacional es una profesión del área de la salud que
              mira al ser humano como un ser ocupacional e integral y tiene como
              objetivo principal facilitar, mantener y mejorar el desempeño de
              las personas en sus actividades del día a día, promoviendo su
              independencia y participación en todos sus contextos.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative" data-aos="fade-right">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-400 text-white">
                      <img
                        src={item.icon}
                        alt="#"
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 text-justify ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
              
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  
                  href="https://encuadrado.com/p/barbara-zamora/"
                  className="text-center w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-700 md:py-4 md:text-lg md:px-11"
                >
                  Reservar en
                  <br />
                  Centro Volantín
                </a>
              </div>
            </div>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img
              className="relative mx-auto shadow-lg rounded-lg"
              width={490}
              src={terapia1}
              alt=""
            />
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-blue-500"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-blue-400 tracking-tight sm:text-3xl">
                Clases de Natación
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Experiencia de 7 años realizando clases de natación a niños y
                adultos.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative" data-aos="fade-left">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-400 text-white">
                        <img
                          src={item.icon}
                          alt="#"
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 text-justify ml-16 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center ">
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="https://api.whatsapp.com/send?phone=56966088903&text="
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700 md:py-4 md:text-lg md:px-3"
                  >
                    Reservar Hora a Domicilio
                  </a>
                  
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="https://carereservas.site.agendapro.com/cl/sucursal/84887"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700 md:py-4 md:text-lg md:px-3"
                  >
                    Reservar Hora en Centro Care
                  </a>
                  
                </div>
              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img
                // TODO MAX IMG
                className="relative mx-auto shadow-lg rounded-lg"
                width={490}
                src={natacion1}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
